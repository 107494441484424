if(!BasicInfo){
  var BasicInfo = {
    initialize: function (onSelectCallback) {
      var self = this
      self.initializeEvents(onSelectCallback)
      self.institutionsAutocomplete(onSelectCallback)
      self.clearIdFieldValue()
      self.addOneAffiliation()
      self.scrollToSection()

      var socialCollection = $('#socials-container')
      this.disableEnter(socialCollection);
      this.disableEnter($('#institutions-container'));

      socialCollection.find('.select-with-icons__wrap').each(function () {
        var socialLink = socialCollection.find('.select-with-icons__wrap').find('input')
        if (socialCollection.find('.select-with-icons__wrap').length === 1 && socialLink.val() === '') {
          self.initSelectDropdown($(this), true)
        } else {
          self.initSelectDropdown($(this))
        }
        self.customSelectFunctionality($(this))

        if (socialLink.val() === '') {
          $('.js-social-add').attr('disabled', 'disabled').addClass('button--disabled');
        }
      })
    },
    initializeEvents: function (onSelectCallback) {
      var self = this
      $(document).on('click', '.js-affiliations-add', function (event) {
        self.institutionsAutocomplete(onSelectCallback)
      })

      $('.js-social-add').on('click', function (event) {
        setTimeout (function () {
          self.addNewSocialMediaForm(true)
        }, 800)
      })

      $('.js-social-delete').on('click', function (event) {
        var socialCollection = $('#socials-container')
        if (socialCollection.find('.select-with-icons__wrap').length === 1) {
          socialCollection.find('input').val('')
          socialCollection.find('select').val(1)
          socialCollection.find('.select-with-icons__container__title').text('Choose Network')
          socialCollection.find('.dropdown-item').removeClass('selected')
          setTimeout(function () {
            $('.js-social-add').attr('disabled', 'disabled').addClass('button--disabled');
          })
        }

        setTimeout(function () {
          if (socialCollection.find('.select-with-icons__wrap').length === 1 && socialCollection.find('select').val() === '') { // case when validation didn't pass.
            socialCollection.find('input').val('')
            $('.js-social-add').attr('disabled', 'disabled').addClass('button--disabled');
          }
        }, 100)
      })
  
      // $(document).on("keypress paste keyup", ".profilename", function(e) {
      //     var element = $(this);
      //     var sufixUrl = element.val();
      //     $("#js_profile_in").text(sufixUrl);
      // });
    },
    clearIdFieldValue: function() {
      var field = '';
      $('.js-affiliations-collection').on('input', '.institution-name', function (e) {
        field = $(e.target).closest('.input-group').find("input[type='hidden']");
        if (field.val() > 0) {
          field.val(0);
        }
      });
    },
    institutionsAutocomplete: function (onSelectCallback) {
      $.each($('.institution-name'), function (key, elem){ // TODO: on addition of new element, this functionality is assigned on each element not only the last one, even though other elements are disabled. Suggestion: call this only for new added element.
        let element = $('#' + $(elem).attr("id"));
        let hiddenElementId = $(elem).attr("id") + "_id";
        let hiddenElement = $("#" + hiddenElementId);
        Autocompletes['affiliations_' + key] = new Autocomplete(
            $(elem),
            '/profile/searchterm/institutions/',
            onSelectCallback,
            hiddenElement,
            {'-1': ['+ Add new institution', 'addNewInstitution'], 'closeForm': true},
            true
        )

        $('#institutions-container').find('input').on('click', function (){
          $(this).focus();
        }); // focus to the list input
      })
    },
    addOneAffiliation: function () {
      if ($('.js-affiliations-wrapper').length == 0) {
        $('.js-affiliations-add').trigger('click')
      }
    },
    initSelectDropdown: function (element, isNewSocialForm = false) {
      let basicSelect = element.find('select')
      basicSelect.find('option').each(function () {
        let option = $(this);
        let value = option.val(),
            text = option.text(),
            icon = option.data('icon'),
            selected = '';

        if (option.is(':selected') && !isNewSocialForm) {
          selected = 'selected';
        }

        let dropdownItem = '<li class="dropdown-item '+selected+'" data-value="'+value+'">' +
            '<div class="dropdown-item__icon"><svg class="icon icon-'+icon+'">' +
            '<use xlink:href="#'+icon+'"></use>' +
            '</svg></div><div class="dropdown-item__text"' +
            '>'+text+'</div></li>';

        element.find('.select-with-icons__container__list').append($(dropdownItem));
      });

      element.find('.select-with-icons__container__title').html(element.find('.dropdown-item.selected').html())
      element.on('click', 'input', function () {
        $(this).focus()
      })
      element.find('.dropdown').foundation();
    },
    customSelectFunctionality: function (element) {
      element.on('click', '.dropdown-item', function () {
        let dropdownItem = $(this);

        if (dropdownItem.hasClass('selected')) {
          return;
        }

        let selectedValue = dropdownItem.data('value'),
            selectedContent = dropdownItem.html(),
            selectTitle = element.find('.select-with-icons__container__title');

        // set this value in real select element
        let hiddenSelect = element.find('select');
        hiddenSelect.val(selectedValue);

        // show selected value in visible part of the component
        element.find('.dropdown-item').removeClass('selected');
        dropdownItem.addClass('selected');
        selectTitle.html(selectedContent);
        selectTitle.data('value', selectedValue);

        $('.js-social-add').attr('disabled', false).removeClass('button--disabled')
        element.find('input').focus();
        this.disableEnter(element);
      });
    },
    addNewSocialMediaForm: function (isNewSocialForm = false) {
      var lastAddedSocialSelect = $('.js-social-collection').find('.select-with-icons__wrap').last()
      this.initSelectDropdown(lastAddedSocialSelect, isNewSocialForm)
      this.customSelectFunctionality(lastAddedSocialSelect)
    },
    disableEnter: function (element) {
      element.on('keydown', 'input', function (e) {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    },
    scrollToSection: function () {
      const urlParams = new URLSearchParams(window.location.search);
      const locationParam = urlParams.get('location');
      if (locationParam) {
        const scrollToEl = $(`#${locationParam}`);
        if (scrollToEl.length) {
          scrollToElement(scrollToEl, 80);
        }
      }
    }
  };
}
